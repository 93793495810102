export const complaintTypesArray =
  [
    "Abandoned Bike",
    "Abandoned Vehicle",
    "Ahv Inspection Unit",
    "Air Quality",
    "Animal In A Park",
    "Animal-Abuse",
    "Appliance",
    "Asbestos",
    "Beach/Pool/Sauna Complaint",
    "Best/Site Safety",
    "Bike Rack Condition",
    "Bike/Roller/Skate Chronic",
    "Blocked Driveway",
    "Boilers",
    "Bridge Condition",
    "Broken Parking Meter",
    "Building Drinking Water Tank",
    "Building/Use",
    "Bus Stop Shelter Complaint",
    "Bus Stop Shelter Placement",
    "Calorie Labeling",
    "Commercial Disposal Complaint",
    "Construction Lead Dust",
    "Consumer Complaint",
    "Cooling Tower",
    "Cranes And Derricks",
    "Curb Condition",
    "Damaged Tree",
    "Day Care",
    "Dead Animal",
    "Dead/Dying Tree",
    "Dep Street Condition",
    "Dept Of Investigations",
    "Derelict Vehicles",
    "Dirty Condition",
    "Disorderly Youth",
    "Door/Window",
    "Drinking",
    "Drinking Water",
    "Drug Activity",
    "Dumpster Complaint",
    "E-Scooter",
    "Electric",
    "Electrical",
    "Elevator",
    "Emergency Response Team (Ert)",
    "Encampment",
    "Ferry Complaint",
    "Ferry Inquiry",
    "Flooring/Stairs",
    "Food Establishment",
    "Food Poisoning",
    "For Hire Vehicle Complaint",
    "For Hire Vehicle Report",
    "Found Property",
    "General",
    "General Construction/Plumbing",
    "Graffiti",
    "Green Taxi Complaint",
    "Green Taxi Report",
    "Harboring Bees/Wasps",
    "Hazardous Materials",
    "Heat/Hot Water",
    "Highway Condition",
    "Highway Sign - Damaged",
    "Highway Sign - Missing",
    "Homeless Person Assistance",
    "Illegal Animal Kept As Pet",
    "Illegal Animal Sold",
    "Illegal Dumping",
    "Illegal Fireworks",
    "Illegal Parking",
    "Illegal Posting",
    "Illegal Tree Damage",
    "Indoor Air Quality",
    "Indoor Sewage",
    "Industrial Waste",
    "Institution Disposal Complaint",
    "Investigations And Discipline (Iad)",
    "Lead",
    "Lifeguard",
    "Linknyc",
    "Litter Basket Complaint",
    "Litter Basket Request",
    "Lost Property",
    "Lot Condition",
    "Maintenance Or Facility",
    "Missed Collection",
    "Mobile Food Vendor",
    "Mold",
    "Mosquitoes",
    "Municipal Parking Facility",
    "New Tree Request",
    "Noise",
    "Noise - Commercial",
    "Noise - Helicopter",
    "Noise - House Of Worship",
    "Noise - Park",
    "Noise - Residential",
    "Noise - Street/Sidewalk",
    "Noise - Vehicle",
    "Non-Emergency Police Matter",
    "Obstruction",
    "Outdoor Dining",
    "Outside Building",
    "Overgrown Tree/Branches",
    "Paint/Plaster",
    "Panhandling",
    "Pet Shop",
    "Plant",
    "Plumbing",
    "Poison Ivy",
    "Public Payphone Complaint",
    "Real Time Enforcement",
    "Residential Disposal Complaint",
    "Rodent",
    "Root/Sewer/Sidewalk Condition",
    "Safety",
    "Sanitation Worker Or Vehicle Complaint",
    "Scaffold Safety",
    "School Maintenance",
    "Sewer",
    "Sidewalk Condition",
    "Smoking",
    "Special Natural Area District (Snad)",
    "Special Projects Inspection Team (Spit)",
    "Standing Water",
    "Street Condition",
    "Street Light Condition",
    "Street Sign - Damaged",
    "Street Sign - Dangling",
    "Street Sign - Missing",
    "Street Sweeping Complaint",
    "Tattooing",
    "Taxi Complaint",
    "Taxi Compliment",
    "Taxi Licensee Complaint",
    "Taxi Report",
    "Traffic",
    "Traffic Signal Condition",
    "Unleashed Dog",
    "Unsanitary Animal Facility",
    "Unsanitary Animal Pvt Property",
    "Unsanitary Condition",
    "Unsanitary Pigeon Condition",
    "Uprooted Stump",
    "Urinating In Public",
    "Vendor Enforcement",
    "Violation Of Park Rules",
    "Water Conservation",
    "Water Leak",
    "Water Quality",
    "Water System",
    "Wood Pile Remaining"
  ]